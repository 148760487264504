<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    USER_GET_USERS,
    USER_INITIAL_GET_USERS_STATE
  } from '@/core/store/user.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ProfileDetailsList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.profileDetails'),
            route: { name: ROUTE_NAME.PROFILE_DETAILS }
          },
          { title: i18nHelper.getMessage('label.profileDetailsList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.USER,
        actions: {
          create: ACTION.USER_CREATE,
          read: ACTION.USER_READ,
          update: ACTION.USER_UPDATE,
          delete: ACTION.USER_DELETE
        }
      },
      filters: [
        {
          label: 'firstName',
          key: 'firstName',
          value: null
        },
        {
          label: 'lastName',
          key: 'lastName',
          value: null
        },
        {
          label: 'companyName',
          key: 'displayName',
          value: null
        },
        {
          label: 'status',
          key: 'isActivated',
          value: null,
          options: listService.getStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'isAccountDeactivated',
          key: 'deactivated',
          value: null,
          options: listService.getYesNoList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'memberType',
          key: 'role',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.userRole),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'mobileDialCode',
          key: 'mobileDialCode',
          value: null,
          options: listService.getDynamicList(
            CONSTANTS_DATA.country,
            false,
            'phone_code',
            'phone_code'
          ),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'mobile',
          key: 'mobile',
          value: null
        },
        {
          label: 'email',
          key: 'email',
          value: null
        },
        {
          label: 'registerDateRange',
          key: 'registerDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        }
      ],
      actions: {
        viewList: {
          module: 'user',
          state: 'users',
          action: USER_GET_USERS,
          initialStateAction: USER_INITIAL_GET_USERS_STATE,
          title: i18nHelper.getMessage('label.getUsers')
        },
        viewDetails: {
          routeName: ROUTE_NAME.PROFILE_DETAILS_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'fname',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'displayName',
          name: 'companyName',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'isActivated',
          name: 'status',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ACTIVATION_STATUS_LABEL
        },
        {
          key: 'isAccountDeactivated',
          name: 'isAccountDeactivated',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            size: 'lg',
            enumKey: 'enumStatusAccountDeactivated',
            statusColor: (value) => {
              let color = {
                0: 'primary',
                1: 'danger'
              };

              return color[value];
            }
          }
        },
        {
          key: 'role',
          name: 'memberType',
          active: true,
          sortable: true
        },
        {
          key: 'contactNumber',
          name: 'contactNumber',
          active: true,
          sortable: true
        },
        {
          key: 'email',
          name: 'email',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'registerDateTime',
          active: true,
          sortable: true,
          sortField: 'createdAtTimeStamp'
        },
        {
          key: 'lastLogin',
          name: 'lastLoginDateTime',
          active: true,
          sortable: true,
          sortField: 'lastLoginTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ]
    })
  };
</script>

<style></style>
